
/*.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh; 
  }*/
  
.content-tile {
    text-align: center;
    color: rgb(200, 200, 200); /* Text color for visibility */
    font-size: 48px;
    padding: 40px
  }