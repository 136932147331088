.posts-link {
    display: block;
    color: rgb(190, 190, 190);
    margin-bottom: 10px;
    gap: 10px;
    text-decoration: none;
    text-align: center;
}

.posts-link:hover {
    text-decoration: none;
    color: #9e1717;
}

h2 {
    font-size: 16px;
    font-weight: normal;
}

.content-tiles {
    display: flex;
    flex-direction: column; /* Ensure content tiles are stacked vertically */
    gap: 10px;
}

.tags-container {
    margin-top: 10px; /* Add space between the excerpt and the tags */
    display: flex;
    flex-wrap: wrap; /* Ensure tags wrap to the next line if necessary */
    gap: 5px; /* Space between tags */
}

.tags-tiles {
    display: inline-flex; /* Ensure each tag stays inline */
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: rgb(30, 39, 41);
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: rgb(190, 190, 190);
    text-align: center;
    text-decoration: none;
}

.content-excerpt {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.content-tile {
    background: #222;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    flex: 1; /* Ensure each tile takes available space */
}

.excerpt-tile {
    background: rgb(30, 39, 41);
    /* gap:10px; */
    padding: 15px;
    text-align: left;
    border-radius: 3px;
    color: rgb(190, 190, 190);
    /* box-sizing: ; */
    font-size: 14px;
    flex-grow: 1; /* Ensure the excerpt expands to fill available space */
    text-decoration: none;
}

.excerpt-link{
    text-decoration: none;
}

.read-more {
    color: #9e1717;
    text-decoration: none;
  }

.read-more:hover {
    text-decoration: none;
    color: #993e3e;
  }