.post-h2 {
  text-align: center;
  font-weight: normal;
}

.post-content img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

.post-content p, .post-content h2, .post-content strong {
    margin: 0;
    padding: 0;
}