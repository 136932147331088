*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5; /* Updated background color for a softer look */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content-tile {
  background: rgb(24, 26, 27);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px 0;
  text-align: left;
  border-radius: 3px;
  color: rgb(190, 190, 190);
  box-sizing: border-box;
  font-size: 14px;
}

.content-tile * {
  margin: 5px;
  /* padding: 0px; */
}

h3 {
  font-size: 18px;
}

h2 {
  font-size: 18px;
}