.sidebar-container {
  flex: 0 0 25%; /* Flex width for the sidebar */
  padding: 20px;
  border-radius: 8px;
  background: transparent; /* Make the sidebar background transparent */
  margin-left: 20px; /* Margin at the left to separate tiles */
}

h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: normal;
}

.sidebar-link {
  display: block;
  color: #9e1717;
  margin-bottom: 5px;
  text-decoration: none;
}

.sidebar-link:hover {
  text-decoration: none;
  color: #993e3e;
}

.qr-code {
  background: rgb(255, 255, 255);
  padding: 2px;
  border-radius: 3px;
  align-content: center;
  text-align: center;
}

.qr_container * {
  margin: 0px;
  padding: 0px;
}